<template>
  <div>
    <div class="form-wrapper">
      <div class="form-wrapper__row">
        <label>Setting1</label>
        <mo-switch v-model="value1" labeld></mo-switch>
      </div>
      <div class="form-wrapper__row">
        <label>Setting2</label>
        <mo-switch v-model="value2" labeld></mo-switch>
      </div>
      <div class="form-wrapper__row">
        <label>Setting3</label>
        <mo-switch v-model="value3" labeld></mo-switch>
      </div>
    </div>
    <div class="modal_footer">
      <mo-button @click="closeModal">Cancel</mo-button>
      <mo-button @click="confirm" main>Okay</mo-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('closed', { result: false });
    },
    confirm() {
      this.$emit('closed', { result: true, settingId: 1, settingValue: true });
    },
  },
  data() {
    return {
      value1: false,
      value2: false,
      value3: false,
    };
  },
};
</script>

<style>
.form-wrapper {
  margin-right: 5px;
  margin-left: 5px;
}

.form-wrapper__row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal_footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
</style>
